@charset "UTF-8";
/**
 * @package     Wordpress.Site
 * @subpackage  Templates.thanh2t
 *
 * @copyright   Copyright (C) 2020 thanh2t. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */
/**
 * @package     Wordpress.Site
 * @subpackage  Templates.thanh2t
 *
 * @copyright   Copyright (C) 2020 thanh2t. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #b22830;
  --bs-orange: #ea630f;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #ffffff;
  --bs-gray: #777777;
  --bs-gray-dark: #929292;
  --bs-gray-100: #f3f3f3;
  --bs-gray-200: #d0d0cf;
  --bs-gray-300: rgba(0, 99, 104, 0.2);
  --bs-gray-400: #e2e2e2;
  --bs-gray-500: #808080;
  --bs-gray-600: #777777;
  --bs-gray-700: #495057;
  --bs-gray-800: #929292;
  --bs-gray-900: #666666;
  --bs-primary: #006368;
  --bs-secondary: #6d35d9;
  --bs-light: #f5eae1;
  --bs-success: #3ea06b;
  --bs-gray-100: #f3f3f3;
  --bs-gray-200: #d0d0cf;
  --bs-gray-300: rgba(0, 99, 104, 0.2);
  --bs-gray-400: #e2e2e2;
  --bs-gray-500: #808080;
  --bs-gray-600: #777777;
  --bs-gray-800: #929292;
  --bs-gray-900: #666666;
  --bs-body: #1a1a1a;
  --bs-info: #21aaff;
  --bs-warning: #ff9922;
  --bs-danger: #b22830;
  --bs-dark: #6d35d9;
  --bs-black: #000000;
  --bs-red: #b22830;
  --bs-orange: #ea630f;
  --bs-white: #ffffff;
  --bs-primary-rgb: 0, 99, 104;
  --bs-secondary-rgb: 109, 53, 217;
  --bs-light-rgb: 245, 234, 225;
  --bs-success-rgb: 62, 160, 107;
  --bs-gray-100-rgb: 243, 243, 243;
  --bs-gray-200-rgb: 208, 208, 207;
  --bs-gray-300-rgb: 0, 99, 104;
  --bs-gray-400-rgb: 226, 226, 226;
  --bs-gray-500-rgb: 128, 128, 128;
  --bs-gray-600-rgb: 119, 119, 119;
  --bs-gray-800-rgb: 146, 146, 146;
  --bs-gray-900-rgb: 102, 102, 102;
  --bs-body-rgb: 26, 26, 26;
  --bs-info-rgb: 33, 170, 255;
  --bs-warning-rgb: 255, 153, 34;
  --bs-danger-rgb: 178, 40, 48;
  --bs-dark-rgb: 109, 53, 217;
  --bs-black-rgb: 0, 0, 0;
  --bs-red-rgb: 178, 40, 48;
  --bs-orange-rgb: 234, 99, 15;
  --bs-white-rgb: 255, 255, 255;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 26, 26, 26;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: "OpenSans", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #1a1a1a;
  --bs-body-bg: #ffffff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 0.6667rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 0.33335rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}

h2 {
  font-size: calc(1.325rem + 0.9vw);
}

h3 {
  font-size: calc(1.2875rem + 0.45vw);
}

h4 {
  font-size: calc(1.275rem + 0.3vw);
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1.125rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #006368;
  text-decoration: underline;
}
a:hover {
  color: #004f53;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #ffffff;
  background-color: #666666;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #777777;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.9375rem);
  padding-left: var(--bs-gutter-x, 0.9375rem);
  margin-right: auto;
  margin-left: auto;
}
.row {
  --bs-gutter-x: 2.133rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-sm,
.gx-sm {
  --bs-gutter-x: 0.33335rem;
}

.g-sm,
.gy-sm {
  --bs-gutter-y: 0.33335rem;
}

.g-3px,
.gx-3px {
  --bs-gutter-x: 3px;
}

.g-3px,
.gy-3px {
  --bs-gutter-y: 3px;
}

.g-15px,
.gx-15px {
  --bs-gutter-x: 15px;
}

.g-15px,
.gy-15px {
  --bs-gutter-y: 15px;
}

.g-25px,
.gx-25px {
  --bs-gutter-x: 25px;
}

.g-25px,
.gy-25px {
  --bs-gutter-y: 25px;
}

.g-35px,
.gx-35px {
  --bs-gutter-x: 35px;
}

.g-35px,
.gy-35px {
  --bs-gutter-y: 35px;
}

.g-700,
.gx-700 {
  --bs-gutter-x: 700px;
}

.g-700,
.gy-700 {
  --bs-gutter-y: 700px;
}

.g-n15,
.gx-n15 {
  --bs-gutter-x: -15px;
}

.g-n15,
.gy-n15 {
  --bs-gutter-y: -15px;
}

.g-n1,
.gx-n1 {
  --bs-gutter-x: -10px;
}

.g-n1,
.gy-n1 {
  --bs-gutter-y: -10px;
}

.g-n5,
.gx-n5 {
  --bs-gutter-x: -5px;
}

.g-n5,
.gy-n5 {
  --bs-gutter-y: -5px;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-117,
.gx-117 {
  --bs-gutter-x: 117px;
}

.g-117,
.gy-117 {
  --bs-gutter-y: 117px;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.6667rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.6667rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 1.3334rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 1.3334rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 2.0001rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 2.0001rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 2.6668rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 2.6668rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3.3335rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3.3335rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 4.0002rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 4.0002rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 4.6669rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 4.6669rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 5.3336rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 5.3336rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 6.0003rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 6.0003rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 6.667rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 6.667rem;
}

.g-gutter,
.gx-gutter {
  --bs-gutter-x: 2.133rem;
}

.g-gutter,
.gy-gutter {
  --bs-gutter-y: 2.133rem;
}

.g-out-container,
.gx-out-container {
  --bs-gutter-x: calc(50% - 50vw);
}

.g-out-container,
.gy-out-container {
  --bs-gutter-y: calc(50% - 50vw);
}
.font-sans-serif {
  font-family: "OpenSans", sans-serif !important;
}

.font-sans-serif-bold {
  font-family: "Oswald", sans-serif !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semi {
  font-weight: 600 !important;
}

.bg-primary {
  background-color: #006368 !important;
}

.bg-secondary {
  background-color: #6d35d9 !important;
}

.bg-light {
  background-color: #f5eae1 !important;
}

.bg-success {
  background-color: #3ea06b !important;
}

.bg-gray-100 {
  background-color: #f3f3f3 !important;
}

.bg-gray-200 {
  background-color: #d0d0cf !important;
}

.bg-gray-300 {
  background-color: rgba(0, 99, 104, 0.2) !important;
}

.bg-gray-400 {
  background-color: #e2e2e2 !important;
}

.bg-gray-500 {
  background-color: #808080 !important;
}

.bg-gray-600 {
  background-color: #777777 !important;
}

.bg-gray-800 {
  background-color: #929292 !important;
}

.bg-gray-900 {
  background-color: #666666 !important;
}

.bg-body {
  background-color: #1a1a1a !important;
}

.bg-info {
  background-color: #21aaff !important;
}

.bg-warning {
  background-color: #ff9922 !important;
}

.bg-danger {
  background-color: #b22830 !important;
}

.bg-dark {
  background-color: #6d35d9 !important;
}

.bg-black {
  background-color: #000000 !important;
}

.bg-red {
  background-color: #b22830 !important;
}

.bg-orange {
  background-color: #ea630f !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.text-primary {
  color: #006368 !important;
}

.text-secondary {
  color: #6d35d9 !important;
}

.text-light {
  color: #f5eae1 !important;
}

.text-success {
  color: #3ea06b !important;
}

.text-gray-100 {
  color: #f3f3f3 !important;
}

.text-gray-200 {
  color: #d0d0cf !important;
}

.text-gray-300 {
  color: rgba(0, 99, 104, 0.2) !important;
}

.text-gray-400 {
  color: #e2e2e2 !important;
}

.text-gray-500 {
  color: #808080 !important;
}

.text-gray-600 {
  color: #777777 !important;
}

.text-gray-800 {
  color: #929292 !important;
}

.text-gray-900 {
  color: #666666 !important;
}

.text-body {
  color: #1a1a1a !important;
}

.text-info {
  color: #21aaff !important;
}

.text-warning {
  color: #ff9922 !important;
}

.text-danger {
  color: #b22830 !important;
}

.text-dark {
  color: #6d35d9 !important;
}

.text-black {
  color: #000000 !important;
}

.text-red {
  color: #b22830 !important;
}

.text-orange {
  color: #ea630f !important;
}

.text-white {
  color: #ffffff !important;
}

.m-sm {
  margin: 0.33335rem !important;
}

.m-3px {
  margin: 3px !important;
}

.m-15px {
  margin: 15px !important;
}

.m-25px {
  margin: 25px !important;
}

.m-35px {
  margin: 35px !important;
}

.m-700 {
  margin: 700px !important;
}

.m-n15 {
  margin: -15px !important;
}

.m-n1 {
  margin: -10px !important;
}

.m-n5 {
  margin: -5px !important;
}

.m-0 {
  margin: 0 !important;
}

.m-117 {
  margin: 117px !important;
}

.m-1 {
  margin: 0.6667rem !important;
}

.m-2 {
  margin: 1.3334rem !important;
}

.m-3 {
  margin: 2.0001rem !important;
}

.m-4 {
  margin: 2.6668rem !important;
}

.m-5 {
  margin: 3.3335rem !important;
}

.m-6 {
  margin: 4.0002rem !important;
}

.m-7 {
  margin: 4.6669rem !important;
}

.m-8 {
  margin: 5.3336rem !important;
}

.m-9 {
  margin: 6.0003rem !important;
}

.m-10 {
  margin: 6.667rem !important;
}

.m-gutter {
  margin: 2.133rem !important;
}

.m-out-container {
  margin: calc(50% - 50vw) !important;
}

.m-auto {
  margin: auto !important;
}

.mx-sm {
  margin-right: 0.33335rem !important;
  margin-left: 0.33335rem !important;
}

.mx-3px {
  margin-right: 3px !important;
  margin-left: 3px !important;
}

.mx-15px {
  margin-right: 15px !important;
  margin-left: 15px !important;
}

.mx-25px {
  margin-right: 25px !important;
  margin-left: 25px !important;
}

.mx-35px {
  margin-right: 35px !important;
  margin-left: 35px !important;
}

.mx-700 {
  margin-right: 700px !important;
  margin-left: 700px !important;
}

.mx-n15 {
  margin-right: -15px !important;
  margin-left: -15px !important;
}

.mx-n1 {
  margin-right: -10px !important;
  margin-left: -10px !important;
}

.mx-n5 {
  margin-right: -5px !important;
  margin-left: -5px !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-117 {
  margin-right: 117px !important;
  margin-left: 117px !important;
}

.mx-1 {
  margin-right: 0.6667rem !important;
  margin-left: 0.6667rem !important;
}

.mx-2 {
  margin-right: 1.3334rem !important;
  margin-left: 1.3334rem !important;
}

.mx-3 {
  margin-right: 2.0001rem !important;
  margin-left: 2.0001rem !important;
}

.mx-4 {
  margin-right: 2.6668rem !important;
  margin-left: 2.6668rem !important;
}

.mx-5 {
  margin-right: 3.3335rem !important;
  margin-left: 3.3335rem !important;
}

.mx-6 {
  margin-right: 4.0002rem !important;
  margin-left: 4.0002rem !important;
}

.mx-7 {
  margin-right: 4.6669rem !important;
  margin-left: 4.6669rem !important;
}

.mx-8 {
  margin-right: 5.3336rem !important;
  margin-left: 5.3336rem !important;
}

.mx-9 {
  margin-right: 6.0003rem !important;
  margin-left: 6.0003rem !important;
}

.mx-10 {
  margin-right: 6.667rem !important;
  margin-left: 6.667rem !important;
}

.mx-gutter {
  margin-right: 2.133rem !important;
  margin-left: 2.133rem !important;
}

.mx-out-container {
  margin-right: calc(50% - 50vw) !important;
  margin-left: calc(50% - 50vw) !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-sm {
  margin-top: 0.33335rem !important;
  margin-bottom: 0.33335rem !important;
}

.my-3px {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.my-15px {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.my-25px {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.my-35px {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.my-700 {
  margin-top: 700px !important;
  margin-bottom: 700px !important;
}

.my-n15 {
  margin-top: -15px !important;
  margin-bottom: -15px !important;
}

.my-n1 {
  margin-top: -10px !important;
  margin-bottom: -10px !important;
}

.my-n5 {
  margin-top: -5px !important;
  margin-bottom: -5px !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-117 {
  margin-top: 117px !important;
  margin-bottom: 117px !important;
}

.my-1 {
  margin-top: 0.6667rem !important;
  margin-bottom: 0.6667rem !important;
}

.my-2 {
  margin-top: 1.3334rem !important;
  margin-bottom: 1.3334rem !important;
}

.my-3 {
  margin-top: 2.0001rem !important;
  margin-bottom: 2.0001rem !important;
}

.my-4 {
  margin-top: 2.6668rem !important;
  margin-bottom: 2.6668rem !important;
}

.my-5 {
  margin-top: 3.3335rem !important;
  margin-bottom: 3.3335rem !important;
}

.my-6 {
  margin-top: 4.0002rem !important;
  margin-bottom: 4.0002rem !important;
}

.my-7 {
  margin-top: 4.6669rem !important;
  margin-bottom: 4.6669rem !important;
}

.my-8 {
  margin-top: 5.3336rem !important;
  margin-bottom: 5.3336rem !important;
}

.my-9 {
  margin-top: 6.0003rem !important;
  margin-bottom: 6.0003rem !important;
}

.my-10 {
  margin-top: 6.667rem !important;
  margin-bottom: 6.667rem !important;
}

.my-gutter {
  margin-top: 2.133rem !important;
  margin-bottom: 2.133rem !important;
}

.my-out-container {
  margin-top: calc(50% - 50vw) !important;
  margin-bottom: calc(50% - 50vw) !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-sm {
  margin-top: 0.33335rem !important;
}

.mt-3px {
  margin-top: 3px !important;
}

.mt-15px {
  margin-top: 15px !important;
}

.mt-25px {
  margin-top: 25px !important;
}

.mt-35px {
  margin-top: 35px !important;
}

.mt-700 {
  margin-top: 700px !important;
}

.mt-n15 {
  margin-top: -15px !important;
}

.mt-n1 {
  margin-top: -10px !important;
}

.mt-n5 {
  margin-top: -5px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-117 {
  margin-top: 117px !important;
}

.mt-1 {
  margin-top: 0.6667rem !important;
}

.mt-2 {
  margin-top: 1.3334rem !important;
}

.mt-3 {
  margin-top: 2.0001rem !important;
}

.mt-4 {
  margin-top: 2.6668rem !important;
}

.mt-5 {
  margin-top: 3.3335rem !important;
}

.mt-6 {
  margin-top: 4.0002rem !important;
}

.mt-7 {
  margin-top: 4.6669rem !important;
}

.mt-8 {
  margin-top: 5.3336rem !important;
}

.mt-9 {
  margin-top: 6.0003rem !important;
}

.mt-10 {
  margin-top: 6.667rem !important;
}

.mt-gutter {
  margin-top: 2.133rem !important;
}

.mt-out-container {
  margin-top: calc(50% - 50vw) !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-sm {
  margin-right: 0.33335rem !important;
}

.me-3px {
  margin-right: 3px !important;
}

.me-15px {
  margin-right: 15px !important;
}

.me-25px {
  margin-right: 25px !important;
}

.me-35px {
  margin-right: 35px !important;
}

.me-700 {
  margin-right: 700px !important;
}

.me-n15 {
  margin-right: -15px !important;
}

.me-n1 {
  margin-right: -10px !important;
}

.me-n5 {
  margin-right: -5px !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-117 {
  margin-right: 117px !important;
}

.me-1 {
  margin-right: 0.6667rem !important;
}

.me-2 {
  margin-right: 1.3334rem !important;
}

.me-3 {
  margin-right: 2.0001rem !important;
}

.me-4 {
  margin-right: 2.6668rem !important;
}

.me-5 {
  margin-right: 3.3335rem !important;
}

.me-6 {
  margin-right: 4.0002rem !important;
}

.me-7 {
  margin-right: 4.6669rem !important;
}

.me-8 {
  margin-right: 5.3336rem !important;
}

.me-9 {
  margin-right: 6.0003rem !important;
}

.me-10 {
  margin-right: 6.667rem !important;
}

.me-gutter {
  margin-right: 2.133rem !important;
}

.me-out-container {
  margin-right: calc(50% - 50vw) !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-sm {
  margin-bottom: 0.33335rem !important;
}

.mb-3px {
  margin-bottom: 3px !important;
}

.mb-15px {
  margin-bottom: 15px !important;
}

.mb-25px {
  margin-bottom: 25px !important;
}

.mb-35px {
  margin-bottom: 35px !important;
}

.mb-700 {
  margin-bottom: 700px !important;
}

.mb-n15 {
  margin-bottom: -15px !important;
}

.mb-n1 {
  margin-bottom: -10px !important;
}

.mb-n5 {
  margin-bottom: -5px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-117 {
  margin-bottom: 117px !important;
}

.mb-1 {
  margin-bottom: 0.6667rem !important;
}

.mb-2 {
  margin-bottom: 1.3334rem !important;
}

.mb-3 {
  margin-bottom: 2.0001rem !important;
}

.mb-4 {
  margin-bottom: 2.6668rem !important;
}

.mb-5 {
  margin-bottom: 3.3335rem !important;
}

.mb-6 {
  margin-bottom: 4.0002rem !important;
}

.mb-7 {
  margin-bottom: 4.6669rem !important;
}

.mb-8 {
  margin-bottom: 5.3336rem !important;
}

.mb-9 {
  margin-bottom: 6.0003rem !important;
}

.mb-10 {
  margin-bottom: 6.667rem !important;
}

.mb-gutter {
  margin-bottom: 2.133rem !important;
}

.mb-out-container {
  margin-bottom: calc(50% - 50vw) !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-sm {
  margin-left: 0.33335rem !important;
}

.ms-3px {
  margin-left: 3px !important;
}

.ms-15px {
  margin-left: 15px !important;
}

.ms-25px {
  margin-left: 25px !important;
}

.ms-35px {
  margin-left: 35px !important;
}

.ms-700 {
  margin-left: 700px !important;
}

.ms-n15 {
  margin-left: -15px !important;
}

.ms-n1 {
  margin-left: -10px !important;
}

.ms-n5 {
  margin-left: -5px !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-117 {
  margin-left: 117px !important;
}

.ms-1 {
  margin-left: 0.6667rem !important;
}

.ms-2 {
  margin-left: 1.3334rem !important;
}

.ms-3 {
  margin-left: 2.0001rem !important;
}

.ms-4 {
  margin-left: 2.6668rem !important;
}

.ms-5 {
  margin-left: 3.3335rem !important;
}

.ms-6 {
  margin-left: 4.0002rem !important;
}

.ms-7 {
  margin-left: 4.6669rem !important;
}

.ms-8 {
  margin-left: 5.3336rem !important;
}

.ms-9 {
  margin-left: 6.0003rem !important;
}

.ms-10 {
  margin-left: 6.667rem !important;
}

.ms-gutter {
  margin-left: 2.133rem !important;
}

.ms-out-container {
  margin-left: calc(50% - 50vw) !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-sm {
  padding: 0.33335rem !important;
}

.p-3px {
  padding: 3px !important;
}

.p-15px {
  padding: 15px !important;
}

.p-25px {
  padding: 25px !important;
}

.p-35px {
  padding: 35px !important;
}

.p-700 {
  padding: 700px !important;
}

.p-n15 {
  padding: -15px !important;
}

.p-n1 {
  padding: -10px !important;
}

.p-n5 {
  padding: -5px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-117 {
  padding: 117px !important;
}

.p-1 {
  padding: 0.6667rem !important;
}

.p-2 {
  padding: 1.3334rem !important;
}

.p-3 {
  padding: 2.0001rem !important;
}

.p-4 {
  padding: 2.6668rem !important;
}

.p-5 {
  padding: 3.3335rem !important;
}

.p-6 {
  padding: 4.0002rem !important;
}

.p-7 {
  padding: 4.6669rem !important;
}

.p-8 {
  padding: 5.3336rem !important;
}

.p-9 {
  padding: 6.0003rem !important;
}

.p-10 {
  padding: 6.667rem !important;
}

.p-gutter {
  padding: 2.133rem !important;
}

.p-out-container {
  padding: calc(50% - 50vw) !important;
}

.px-sm {
  padding-right: 0.33335rem !important;
  padding-left: 0.33335rem !important;
}

.px-3px {
  padding-right: 3px !important;
  padding-left: 3px !important;
}

.px-15px {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.px-25px {
  padding-right: 25px !important;
  padding-left: 25px !important;
}

.px-35px {
  padding-right: 35px !important;
  padding-left: 35px !important;
}

.px-700 {
  padding-right: 700px !important;
  padding-left: 700px !important;
}

.px-n15 {
  padding-right: -15px !important;
  padding-left: -15px !important;
}

.px-n1 {
  padding-right: -10px !important;
  padding-left: -10px !important;
}

.px-n5 {
  padding-right: -5px !important;
  padding-left: -5px !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-117 {
  padding-right: 117px !important;
  padding-left: 117px !important;
}

.px-1 {
  padding-right: 0.6667rem !important;
  padding-left: 0.6667rem !important;
}

.px-2 {
  padding-right: 1.3334rem !important;
  padding-left: 1.3334rem !important;
}

.px-3 {
  padding-right: 2.0001rem !important;
  padding-left: 2.0001rem !important;
}

.px-4 {
  padding-right: 2.6668rem !important;
  padding-left: 2.6668rem !important;
}

.px-5 {
  padding-right: 3.3335rem !important;
  padding-left: 3.3335rem !important;
}

.px-6 {
  padding-right: 4.0002rem !important;
  padding-left: 4.0002rem !important;
}

.px-7 {
  padding-right: 4.6669rem !important;
  padding-left: 4.6669rem !important;
}

.px-8 {
  padding-right: 5.3336rem !important;
  padding-left: 5.3336rem !important;
}

.px-9 {
  padding-right: 6.0003rem !important;
  padding-left: 6.0003rem !important;
}

.px-10 {
  padding-right: 6.667rem !important;
  padding-left: 6.667rem !important;
}

.px-gutter {
  padding-right: 2.133rem !important;
  padding-left: 2.133rem !important;
}

.px-out-container {
  padding-right: calc(50% - 50vw) !important;
  padding-left: calc(50% - 50vw) !important;
}

.py-sm {
  padding-top: 0.33335rem !important;
  padding-bottom: 0.33335rem !important;
}

.py-3px {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.py-15px {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.py-25px {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.py-35px {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.py-700 {
  padding-top: 700px !important;
  padding-bottom: 700px !important;
}

.py-n15 {
  padding-top: -15px !important;
  padding-bottom: -15px !important;
}

.py-n1 {
  padding-top: -10px !important;
  padding-bottom: -10px !important;
}

.py-n5 {
  padding-top: -5px !important;
  padding-bottom: -5px !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-117 {
  padding-top: 117px !important;
  padding-bottom: 117px !important;
}

.py-1 {
  padding-top: 0.6667rem !important;
  padding-bottom: 0.6667rem !important;
}

.py-2 {
  padding-top: 1.3334rem !important;
  padding-bottom: 1.3334rem !important;
}

.py-3 {
  padding-top: 2.0001rem !important;
  padding-bottom: 2.0001rem !important;
}

.py-4 {
  padding-top: 2.6668rem !important;
  padding-bottom: 2.6668rem !important;
}

.py-5 {
  padding-top: 3.3335rem !important;
  padding-bottom: 3.3335rem !important;
}

.py-6 {
  padding-top: 4.0002rem !important;
  padding-bottom: 4.0002rem !important;
}

.py-7 {
  padding-top: 4.6669rem !important;
  padding-bottom: 4.6669rem !important;
}

.py-8 {
  padding-top: 5.3336rem !important;
  padding-bottom: 5.3336rem !important;
}

.py-9 {
  padding-top: 6.0003rem !important;
  padding-bottom: 6.0003rem !important;
}

.py-10 {
  padding-top: 6.667rem !important;
  padding-bottom: 6.667rem !important;
}

.py-gutter {
  padding-top: 2.133rem !important;
  padding-bottom: 2.133rem !important;
}

.py-out-container {
  padding-top: calc(50% - 50vw) !important;
  padding-bottom: calc(50% - 50vw) !important;
}

.pt-sm {
  padding-top: 0.33335rem !important;
}

.pt-3px {
  padding-top: 3px !important;
}

.pt-15px {
  padding-top: 15px !important;
}

.pt-25px {
  padding-top: 25px !important;
}

.pt-35px {
  padding-top: 35px !important;
}

.pt-700 {
  padding-top: 700px !important;
}

.pt-n15 {
  padding-top: -15px !important;
}

.pt-n1 {
  padding-top: -10px !important;
}

.pt-n5 {
  padding-top: -5px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-117 {
  padding-top: 117px !important;
}

.pt-1 {
  padding-top: 0.6667rem !important;
}

.pt-2 {
  padding-top: 1.3334rem !important;
}

.pt-3 {
  padding-top: 2.0001rem !important;
}

.pt-4 {
  padding-top: 2.6668rem !important;
}

.pt-5 {
  padding-top: 3.3335rem !important;
}

.pt-6 {
  padding-top: 4.0002rem !important;
}

.pt-7 {
  padding-top: 4.6669rem !important;
}

.pt-8 {
  padding-top: 5.3336rem !important;
}

.pt-9 {
  padding-top: 6.0003rem !important;
}

.pt-10 {
  padding-top: 6.667rem !important;
}

.pt-gutter {
  padding-top: 2.133rem !important;
}

.pt-out-container {
  padding-top: calc(50% - 50vw) !important;
}

.pe-sm {
  padding-right: 0.33335rem !important;
}

.pe-3px {
  padding-right: 3px !important;
}

.pe-15px {
  padding-right: 15px !important;
}

.pe-25px {
  padding-right: 25px !important;
}

.pe-35px {
  padding-right: 35px !important;
}

.pe-700 {
  padding-right: 700px !important;
}

.pe-n15 {
  padding-right: -15px !important;
}

.pe-n1 {
  padding-right: -10px !important;
}

.pe-n5 {
  padding-right: -5px !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-117 {
  padding-right: 117px !important;
}

.pe-1 {
  padding-right: 0.6667rem !important;
}

.pe-2 {
  padding-right: 1.3334rem !important;
}

.pe-3 {
  padding-right: 2.0001rem !important;
}

.pe-4 {
  padding-right: 2.6668rem !important;
}

.pe-5 {
  padding-right: 3.3335rem !important;
}

.pe-6 {
  padding-right: 4.0002rem !important;
}

.pe-7 {
  padding-right: 4.6669rem !important;
}

.pe-8 {
  padding-right: 5.3336rem !important;
}

.pe-9 {
  padding-right: 6.0003rem !important;
}

.pe-10 {
  padding-right: 6.667rem !important;
}

.pe-gutter {
  padding-right: 2.133rem !important;
}

.pe-out-container {
  padding-right: calc(50% - 50vw) !important;
}

.pb-sm {
  padding-bottom: 0.33335rem !important;
}

.pb-3px {
  padding-bottom: 3px !important;
}

.pb-15px {
  padding-bottom: 15px !important;
}

.pb-25px {
  padding-bottom: 25px !important;
}

.pb-35px {
  padding-bottom: 35px !important;
}

.pb-700 {
  padding-bottom: 700px !important;
}

.pb-n15 {
  padding-bottom: -15px !important;
}

.pb-n1 {
  padding-bottom: -10px !important;
}

.pb-n5 {
  padding-bottom: -5px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-117 {
  padding-bottom: 117px !important;
}

.pb-1 {
  padding-bottom: 0.6667rem !important;
}

.pb-2 {
  padding-bottom: 1.3334rem !important;
}

.pb-3 {
  padding-bottom: 2.0001rem !important;
}

.pb-4 {
  padding-bottom: 2.6668rem !important;
}

.pb-5 {
  padding-bottom: 3.3335rem !important;
}

.pb-6 {
  padding-bottom: 4.0002rem !important;
}

.pb-7 {
  padding-bottom: 4.6669rem !important;
}

.pb-8 {
  padding-bottom: 5.3336rem !important;
}

.pb-9 {
  padding-bottom: 6.0003rem !important;
}

.pb-10 {
  padding-bottom: 6.667rem !important;
}

.pb-gutter {
  padding-bottom: 2.133rem !important;
}

.pb-out-container {
  padding-bottom: calc(50% - 50vw) !important;
}

.ps-sm {
  padding-left: 0.33335rem !important;
}

.ps-3px {
  padding-left: 3px !important;
}

.ps-15px {
  padding-left: 15px !important;
}

.ps-25px {
  padding-left: 25px !important;
}

.ps-35px {
  padding-left: 35px !important;
}

.ps-700 {
  padding-left: 700px !important;
}

.ps-n15 {
  padding-left: -15px !important;
}

.ps-n1 {
  padding-left: -10px !important;
}

.ps-n5 {
  padding-left: -5px !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-117 {
  padding-left: 117px !important;
}

.ps-1 {
  padding-left: 0.6667rem !important;
}

.ps-2 {
  padding-left: 1.3334rem !important;
}

.ps-3 {
  padding-left: 2.0001rem !important;
}

.ps-4 {
  padding-left: 2.6668rem !important;
}

.ps-5 {
  padding-left: 3.3335rem !important;
}

.ps-6 {
  padding-left: 4.0002rem !important;
}

.ps-7 {
  padding-left: 4.6669rem !important;
}

.ps-8 {
  padding-left: 5.3336rem !important;
}

.ps-9 {
  padding-left: 6.0003rem !important;
}

.ps-10 {
  padding-left: 6.667rem !important;
}

.ps-gutter {
  padding-left: 2.133rem !important;
}

.ps-out-container {
  padding-left: calc(50% - 50vw) !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-sm {
  gap: 0.33335rem !important;
}

.gap-3px {
  gap: 3px !important;
}

.gap-15px {
  gap: 15px !important;
}

.gap-25px {
  gap: 25px !important;
}

.gap-35px {
  gap: 35px !important;
}

.gap-700 {
  gap: 700px !important;
}

.gap-n15 {
  gap: -15px !important;
}

.gap-n1 {
  gap: -10px !important;
}

.gap-n5 {
  gap: -5px !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-117 {
  gap: 117px !important;
}

.gap-1 {
  gap: 0.6667rem !important;
}

.gap-2 {
  gap: 1.3334rem !important;
}

.gap-3 {
  gap: 2.0001rem !important;
}

.gap-4 {
  gap: 2.6668rem !important;
}

.gap-5 {
  gap: 3.3335rem !important;
}

.gap-6 {
  gap: 4.0002rem !important;
}

.gap-7 {
  gap: 4.6669rem !important;
}

.gap-8 {
  gap: 5.3336rem !important;
}

.gap-9 {
  gap: 6.0003rem !important;
}

.gap-10 {
  gap: 6.667rem !important;
}

.gap-gutter {
  gap: 2.133rem !important;
}

.gap-out-container {
  gap: calc(50% - 50vw) !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.o-0 {
  opacity: 0 !important;
}

.o-25 {
  opacity: 0.25 !important;
}

.o-50 {
  opacity: 0.5 !important;
}

.o-70 {
  opacity: 0.7 !important;
}

.o-75 {
  opacity: 0.75 !important;
}

.o-85 {
  opacity: 0.85 !important;
}

.o-100 {
  opacity: 1 !important;
}

.z-1 {
  z-index: 9 !important;
}

.z-2 {
  z-index: 99 !important;
}

.z-3 {
  z-index: 999 !important;
}

.z-4 {
  z-index: 1039 !important;
}

.z-5 {
  z-index: 9999 !important;
}

.object-cover {
  -o-object-fit: cover !important;
     object-fit: cover !important;
}

.object-contain {
  -o-object-fit: contain !important;
     object-fit: contain !important;
}

.object-full {
  -o-object-fit: full !important;
     object-fit: full !important;
}

.object-position-top {
  -o-object-position: top !important;
     object-position: top !important;
}

.object-position-center {
  -o-object-position: center !important;
     object-position: center !important;
}

.object-position-bottom {
  -o-object-position: bottom !important;
     object-position: bottom !important;
}

.vh-30 {
  height: 30vh !important;
}

.vh-40 {
  height: 40vh !important;
}

.vh-50 {
  height: 50vh !important;
}

.vh-60 {
  height: 60vh !important;
}

.vh-65 {
  height: 65vh !important;
}

.vh-70 {
  height: 70vh !important;
}

.vh-80 {
  height: 80vh !important;
}

.vh-100 {
  height: 100vh !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-20px {
  width: 20px !important;
}

.w-80px {
  width: 80px !important;
}

.w-180 {
  width: 180px !important;
}

.w-200px {
  width: 200px !important;
}

.w-100px {
  width: 100px !important;
}

.w-cal200 {
  width: calc(100% - 200px) !important;
}

.mw-10 {
  max-width: 10% !important;
}

.mw-20 {
  max-width: 20% !important;
}

.mw-30 {
  max-width: 30% !important;
}

.mw-40 {
  max-width: 40% !important;
}

.mw-50 {
  max-width: 50% !important;
}

.mw-60 {
  max-width: 60% !important;
}

.mw-70 {
  max-width: 70% !important;
}

.mw-75 {
  max-width: 75% !important;
}

.mw-80 {
  max-width: 80% !important;
}

.mw-90 {
  max-width: 90% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mw-auto {
  max-width: auto !important;
}

.mw-350 {
  max-width: 350px !important;
}

.mw-824 {
  max-width: 824px !important;
}

.mw-890 {
  max-width: 890px !important;
}

.mw-1100 {
  max-width: 1100px !important;
}

.mw-1410 {
  max-width: 1410px !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-20px {
  height: 20px !important;
}

.h-38px {
  height: 38px !important;
}

.h-350 {
  height: 350px !important;
}

.h-245 {
  height: 245px !important;
}

.h-250 {
  height: 250px !important;
}

.h-255 {
  height: 255px !important;
}

.h-260 {
  height: 260px !important;
}

.h-auto {
  height: auto !important;
}

.mh-600 {
  min-height: 600px !important;
}

.mh-auto {
  min-height: auto !important;
}

.fs-1 {
  font-size: 2.5rem !important;
}

.fs-2 {
  font-size: 2rem !important;
}

.fs-3 {
  font-size: 1.625rem !important;
}

.fs-4 {
  font-size: 1.5rem !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1.125rem !important;
}

.fs-7 {
  font-size: 1rem !important;
}

.fs-xl {
  font-size: 3.75rem !important;
}

.fs-lg {
  font-size: 1.875rem !important;
}

.fs-md {
  font-size: 2.25rem !important;
}

.fs-sm {
  font-size: 0.875rem !important;
}

.fs-xs {
  font-size: 0.75rem !important;
}

.fs-xxs {
  font-size: 0.625rem !important;
}

.transform-none {
  transform: none !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-md {
  line-height: 1.65 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.lh-3 {
  line-height: 3 !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.order-unset {
  order: unset !important;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-grab {
  cursor: -webkit-grab !important;
  cursor: grab !important;
}

.border {
  border: 1px solid #d6d6d6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #d6d6d6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-start {
  border-left: 1px solid #d6d6d6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-end {
  border-right: 1px solid #d6d6d6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #d6d6d6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-primary {
  border-color: #006368 !important;
}

.border-secondary {
  border-color: #6d35d9 !important;
}

.border-light {
  border-color: #f5eae1 !important;
}

.border-success {
  border-color: #3ea06b !important;
}

.border-gray-100 {
  border-color: #f3f3f3 !important;
}

.border-gray-200 {
  border-color: #d0d0cf !important;
}

.border-gray-300 {
  border-color: rgba(0, 99, 104, 0.2) !important;
}

.border-gray-400 {
  border-color: #e2e2e2 !important;
}

.border-gray-500 {
  border-color: #808080 !important;
}

.border-gray-600 {
  border-color: #777777 !important;
}

.border-gray-800 {
  border-color: #929292 !important;
}

.border-gray-900 {
  border-color: #666666 !important;
}

.border-body {
  border-color: #1a1a1a !important;
}

.border-info {
  border-color: #21aaff !important;
}

.border-warning {
  border-color: #ff9922 !important;
}

.border-danger {
  border-color: #b22830 !important;
}

.border-dark {
  border-color: #6d35d9 !important;
}

.border-black {
  border-color: #000000 !important;
}

.border-red {
  border-color: #b22830 !important;
}

.border-orange {
  border-color: #ea630f !important;
}

.border-white {
  border-color: #ffffff !important;
}

.shadow {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-25 {
  top: 25% !important;
}

.top-50 {
  top: 50% !important;
}

.top-75 {
  top: 75% !important;
}

.top-100 {
  top: 100% !important;
}

.top-10 {
  top: 10px !important;
}

.top-20 {
  top: 20px !important;
}

.top-30 {
  top: 30px !important;
}

.top-n10 {
  top: -10px !important;
}

.top-n20 {
  top: -20px !important;
}

.top-n8 {
  top: -8px !important;
}

.top-7 {
  top: 7px !important;
}

.top-13 {
  top: 13px !important;
}

.top-15 {
  top: 15px !important;
}

.top-n3 {
  top: -3px !important;
}

.top-5 {
  top: 5px !important;
}

.top-2 {
  top: 2px !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-25 {
  bottom: 25% !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-75 {
  bottom: 75% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.bottom-10 {
  bottom: 10px !important;
}

.bottom-20 {
  bottom: 20px !important;
}

.bottom-30 {
  bottom: 30px !important;
}

.bottom-n10 {
  bottom: -10px !important;
}

.bottom-n20 {
  bottom: -20px !important;
}

.bottom-n8 {
  bottom: -8px !important;
}

.bottom-7 {
  bottom: 7px !important;
}

.bottom-13 {
  bottom: 13px !important;
}

.bottom-15 {
  bottom: 15px !important;
}

.bottom-n3 {
  bottom: -3px !important;
}

.bottom-5 {
  bottom: 5px !important;
}

.bottom-2 {
  bottom: 2px !important;
}

.start-0 {
  left: 0 !important;
}

.start-25 {
  left: 25% !important;
}

.start-50 {
  left: 50% !important;
}

.start-75 {
  left: 75% !important;
}

.start-100 {
  left: 100% !important;
}

.start-10 {
  left: 10px !important;
}

.start-20 {
  left: 20px !important;
}

.start-30 {
  left: 30px !important;
}

.start-n10 {
  left: -10px !important;
}

.start-n20 {
  left: -20px !important;
}

.start-n8 {
  left: -8px !important;
}

.start-7 {
  left: 7px !important;
}

.start-13 {
  left: 13px !important;
}

.start-15 {
  left: 15px !important;
}

.start-n3 {
  left: -3px !important;
}

.start-5 {
  left: 5px !important;
}

.start-2 {
  left: 2px !important;
}

.end-0 {
  right: 0 !important;
}

.end-25 {
  right: 25% !important;
}

.end-50 {
  right: 50% !important;
}

.end-75 {
  right: 75% !important;
}

.end-100 {
  right: 100% !important;
}

.end-10 {
  right: 10px !important;
}

.end-20 {
  right: 20px !important;
}

.end-30 {
  right: 30px !important;
}

.end-n10 {
  right: -10px !important;
}

.end-n20 {
  right: -20px !important;
}

.end-n8 {
  right: -8px !important;
}

.end-7 {
  right: 7px !important;
}

.end-13 {
  right: 13px !important;
}

.end-15 {
  right: 15px !important;
}

.end-n3 {
  right: -3px !important;
}

.end-5 {
  right: 5px !important;
}

.end-2 {
  right: 2px !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-15px {
  border-radius: 15px !important;
}

.rounded-20px {
  border-radius: 20px !important;
}

.rounded-30px {
  border-radius: 30px !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.pevents-none {
  pointer-events: none !important;
}

.pevents-auto {
  pointer-events: auto !important;
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #006368;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #004f53;
}
.nav-link.disabled {
  color: #777777;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid rgba(0, 99, 104, 0.2);
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #d0d0cf #d0d0cf rgba(0, 99, 104, 0.2);
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #777777;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ffffff;
  border-color: rgba(0, 99, 104, 0.2) rgba(0, 99, 104, 0.2) #ffffff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #006368;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.33335rem;
  padding-bottom: 0.33335rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: -0.15625rem;
  padding-bottom: -0.15625rem;
  margin-right: 1rem;
  font-size: calc(1.3125rem + 0.75vw);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: calc(1.3125rem + 0.75vw);
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #ffffff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #1a1a1a;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #1a1a1a;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 99, 104, 0.25);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #ffffff;
  background-color: #006368;
  border-color: #006368;
}
.btn-primary:hover {
  color: #ffffff;
  background-color: #005458;
  border-color: #004f53;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #ffffff;
  background-color: #005458;
  border-color: #004f53;
  box-shadow: 0 0 0 0.25rem rgba(38, 122, 127, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #004f53;
  border-color: #004a4e;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 122, 127, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #ffffff;
  background-color: #006368;
  border-color: #006368;
}

.btn-secondary {
  color: #ffffff;
  background-color: #6d35d9;
  border-color: #6d35d9;
}
.btn-secondary:hover {
  color: #ffffff;
  background-color: #5d2db8;
  border-color: #572aae;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #ffffff;
  background-color: #5d2db8;
  border-color: #572aae;
  box-shadow: 0 0 0 0.25rem rgba(131, 83, 223, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #572aae;
  border-color: #5228a3;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(131, 83, 223, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #ffffff;
  background-color: #6d35d9;
  border-color: #6d35d9;
}

.btn-light {
  color: #000000;
  background-color: #f5eae1;
  border-color: #f5eae1;
}
.btn-light:hover {
  color: #000000;
  background-color: #f7ede6;
  border-color: #f6ece4;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000000;
  background-color: #f7ede6;
  border-color: #f6ece4;
  box-shadow: 0 0 0 0.25rem rgba(208, 199, 191, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000000;
  background-color: #f7eee7;
  border-color: #f6ece4;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(208, 199, 191, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000000;
  background-color: #f5eae1;
  border-color: #f5eae1;
}

.btn-success {
  color: #000000;
  background-color: #3ea06b;
  border-color: #3ea06b;
}
.btn-success:hover {
  color: #000000;
  background-color: #5bae81;
  border-color: #51aa7a;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #000000;
  background-color: #5bae81;
  border-color: #51aa7a;
  box-shadow: 0 0 0 0.25rem rgba(53, 136, 91, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #000000;
  background-color: #65b389;
  border-color: #51aa7a;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(53, 136, 91, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #000000;
  background-color: #3ea06b;
  border-color: #3ea06b;
}

.btn-gray-100 {
  color: #000000;
  background-color: #f3f3f3;
  border-color: #f3f3f3;
}
.btn-gray-100:hover {
  color: #000000;
  background-color: whitesmoke;
  border-color: #f4f4f4;
}
.btn-check:focus + .btn-gray-100, .btn-gray-100:focus {
  color: #000000;
  background-color: whitesmoke;
  border-color: #f4f4f4;
  box-shadow: 0 0 0 0.25rem rgba(207, 207, 207, 0.5);
}
.btn-check:checked + .btn-gray-100, .btn-check:active + .btn-gray-100, .btn-gray-100:active, .btn-gray-100.active, .show > .btn-gray-100.dropdown-toggle {
  color: #000000;
  background-color: whitesmoke;
  border-color: #f4f4f4;
}
.btn-check:checked + .btn-gray-100:focus, .btn-check:active + .btn-gray-100:focus, .btn-gray-100:active:focus, .btn-gray-100.active:focus, .show > .btn-gray-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(207, 207, 207, 0.5);
}
.btn-gray-100:disabled, .btn-gray-100.disabled {
  color: #000000;
  background-color: #f3f3f3;
  border-color: #f3f3f3;
}

.btn-gray-200 {
  color: #000000;
  background-color: #d0d0cf;
  border-color: #d0d0cf;
}
.btn-gray-200:hover {
  color: #000000;
  background-color: #d7d7d6;
  border-color: #d5d5d4;
}
.btn-check:focus + .btn-gray-200, .btn-gray-200:focus {
  color: #000000;
  background-color: #d7d7d6;
  border-color: #d5d5d4;
  box-shadow: 0 0 0 0.25rem rgba(177, 177, 176, 0.5);
}
.btn-check:checked + .btn-gray-200, .btn-check:active + .btn-gray-200, .btn-gray-200:active, .btn-gray-200.active, .show > .btn-gray-200.dropdown-toggle {
  color: #000000;
  background-color: #d9d9d9;
  border-color: #d5d5d4;
}
.btn-check:checked + .btn-gray-200:focus, .btn-check:active + .btn-gray-200:focus, .btn-gray-200:active:focus, .btn-gray-200.active:focus, .show > .btn-gray-200.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(177, 177, 176, 0.5);
}
.btn-gray-200:disabled, .btn-gray-200.disabled {
  color: #000000;
  background-color: #d0d0cf;
  border-color: #d0d0cf;
}

.btn-gray-300 {
  color: #ffffff;
  background-color: rgba(0, 99, 104, 0.2);
  border-color: rgba(0, 99, 104, 0.2);
}
.btn-gray-300:hover {
  color: #ffffff;
  background-color: rgba(0, 38, 40, 0.32);
  border-color: rgba(0, 30, 32, 0.36);
}
.btn-check:focus + .btn-gray-300, .btn-gray-300:focus {
  color: #ffffff;
  background-color: rgba(0, 38, 40, 0.32);
  border-color: rgba(0, 30, 32, 0.36);
  box-shadow: 0 0 0 0.25rem rgba(156, 195, 197, 0.5);
}
.btn-check:checked + .btn-gray-300, .btn-check:active + .btn-gray-300, .btn-gray-300:active, .btn-gray-300.active, .show > .btn-gray-300.dropdown-toggle {
  color: #ffffff;
  background-color: rgba(0, 30, 32, 0.36);
  border-color: rgba(0, 25, 26, 0.4);
}
.btn-check:checked + .btn-gray-300:focus, .btn-check:active + .btn-gray-300:focus, .btn-gray-300:active:focus, .btn-gray-300.active:focus, .show > .btn-gray-300.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(156, 195, 197, 0.5);
}
.btn-gray-300:disabled, .btn-gray-300.disabled {
  color: #ffffff;
  background-color: rgba(0, 99, 104, 0.2);
  border-color: rgba(0, 99, 104, 0.2);
}

.btn-gray-400 {
  color: #000000;
  background-color: #e2e2e2;
  border-color: #e2e2e2;
}
.btn-gray-400:hover {
  color: #000000;
  background-color: #e6e6e6;
  border-color: #e5e5e5;
}
.btn-check:focus + .btn-gray-400, .btn-gray-400:focus {
  color: #000000;
  background-color: #e6e6e6;
  border-color: #e5e5e5;
  box-shadow: 0 0 0 0.25rem rgba(192, 192, 192, 0.5);
}
.btn-check:checked + .btn-gray-400, .btn-check:active + .btn-gray-400, .btn-gray-400:active, .btn-gray-400.active, .show > .btn-gray-400.dropdown-toggle {
  color: #000000;
  background-color: #e8e8e8;
  border-color: #e5e5e5;
}
.btn-check:checked + .btn-gray-400:focus, .btn-check:active + .btn-gray-400:focus, .btn-gray-400:active:focus, .btn-gray-400.active:focus, .show > .btn-gray-400.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(192, 192, 192, 0.5);
}
.btn-gray-400:disabled, .btn-gray-400.disabled {
  color: #000000;
  background-color: #e2e2e2;
  border-color: #e2e2e2;
}

.btn-gray-500 {
  color: #000000;
  background-color: #808080;
  border-color: #808080;
}
.btn-gray-500:hover {
  color: #000000;
  background-color: #939393;
  border-color: #8d8d8d;
}
.btn-check:focus + .btn-gray-500, .btn-gray-500:focus {
  color: #000000;
  background-color: #939393;
  border-color: #8d8d8d;
  box-shadow: 0 0 0 0.25rem rgba(109, 109, 109, 0.5);
}
.btn-check:checked + .btn-gray-500, .btn-check:active + .btn-gray-500, .btn-gray-500:active, .btn-gray-500.active, .show > .btn-gray-500.dropdown-toggle {
  color: #000000;
  background-color: #999999;
  border-color: #8d8d8d;
}
.btn-check:checked + .btn-gray-500:focus, .btn-check:active + .btn-gray-500:focus, .btn-gray-500:active:focus, .btn-gray-500.active:focus, .show > .btn-gray-500.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(109, 109, 109, 0.5);
}
.btn-gray-500:disabled, .btn-gray-500.disabled {
  color: #000000;
  background-color: #808080;
  border-color: #808080;
}

.btn-gray-600 {
  color: #000000;
  background-color: #777777;
  border-color: #777777;
}
.btn-gray-600:hover {
  color: #000000;
  background-color: #8b8b8b;
  border-color: #858585;
}
.btn-check:focus + .btn-gray-600, .btn-gray-600:focus {
  color: #000000;
  background-color: #8b8b8b;
  border-color: #858585;
  box-shadow: 0 0 0 0.25rem rgba(101, 101, 101, 0.5);
}
.btn-check:checked + .btn-gray-600, .btn-check:active + .btn-gray-600, .btn-gray-600:active, .btn-gray-600.active, .show > .btn-gray-600.dropdown-toggle {
  color: #000000;
  background-color: #929292;
  border-color: #858585;
}
.btn-check:checked + .btn-gray-600:focus, .btn-check:active + .btn-gray-600:focus, .btn-gray-600:active:focus, .btn-gray-600.active:focus, .show > .btn-gray-600.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(101, 101, 101, 0.5);
}
.btn-gray-600:disabled, .btn-gray-600.disabled {
  color: #000000;
  background-color: #777777;
  border-color: #777777;
}

.btn-gray-800 {
  color: #000000;
  background-color: #929292;
  border-color: #929292;
}
.btn-gray-800:hover {
  color: #000000;
  background-color: #a2a2a2;
  border-color: #9d9d9d;
}
.btn-check:focus + .btn-gray-800, .btn-gray-800:focus {
  color: #000000;
  background-color: #a2a2a2;
  border-color: #9d9d9d;
  box-shadow: 0 0 0 0.25rem rgba(124, 124, 124, 0.5);
}
.btn-check:checked + .btn-gray-800, .btn-check:active + .btn-gray-800, .btn-gray-800:active, .btn-gray-800.active, .show > .btn-gray-800.dropdown-toggle {
  color: #000000;
  background-color: #a8a8a8;
  border-color: #9d9d9d;
}
.btn-check:checked + .btn-gray-800:focus, .btn-check:active + .btn-gray-800:focus, .btn-gray-800:active:focus, .btn-gray-800.active:focus, .show > .btn-gray-800.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(124, 124, 124, 0.5);
}
.btn-gray-800:disabled, .btn-gray-800.disabled {
  color: #000000;
  background-color: #929292;
  border-color: #929292;
}

.btn-gray-900 {
  color: #ffffff;
  background-color: #666666;
  border-color: #666666;
}
.btn-gray-900:hover {
  color: #ffffff;
  background-color: #575757;
  border-color: #525252;
}
.btn-check:focus + .btn-gray-900, .btn-gray-900:focus {
  color: #ffffff;
  background-color: #575757;
  border-color: #525252;
  box-shadow: 0 0 0 0.25rem rgba(125, 125, 125, 0.5);
}
.btn-check:checked + .btn-gray-900, .btn-check:active + .btn-gray-900, .btn-gray-900:active, .btn-gray-900.active, .show > .btn-gray-900.dropdown-toggle {
  color: #ffffff;
  background-color: #525252;
  border-color: #4d4d4d;
}
.btn-check:checked + .btn-gray-900:focus, .btn-check:active + .btn-gray-900:focus, .btn-gray-900:active:focus, .btn-gray-900.active:focus, .show > .btn-gray-900.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(125, 125, 125, 0.5);
}
.btn-gray-900:disabled, .btn-gray-900.disabled {
  color: #ffffff;
  background-color: #666666;
  border-color: #666666;
}

.btn-body {
  color: #ffffff;
  background-color: #1a1a1a;
  border-color: #1a1a1a;
}
.btn-body:hover {
  color: #ffffff;
  background-color: #161616;
  border-color: #151515;
}
.btn-check:focus + .btn-body, .btn-body:focus {
  color: #ffffff;
  background-color: #161616;
  border-color: #151515;
  box-shadow: 0 0 0 0.25rem rgba(60, 60, 60, 0.5);
}
.btn-check:checked + .btn-body, .btn-check:active + .btn-body, .btn-body:active, .btn-body.active, .show > .btn-body.dropdown-toggle {
  color: #ffffff;
  background-color: #151515;
  border-color: #141414;
}
.btn-check:checked + .btn-body:focus, .btn-check:active + .btn-body:focus, .btn-body:active:focus, .btn-body.active:focus, .show > .btn-body.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 60, 60, 0.5);
}
.btn-body:disabled, .btn-body.disabled {
  color: #ffffff;
  background-color: #1a1a1a;
  border-color: #1a1a1a;
}

.btn-info {
  color: #000000;
  background-color: #21aaff;
  border-color: #21aaff;
}
.btn-info:hover {
  color: #000000;
  background-color: #42b7ff;
  border-color: #37b3ff;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000000;
  background-color: #42b7ff;
  border-color: #37b3ff;
  box-shadow: 0 0 0 0.25rem rgba(28, 145, 217, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #000000;
  background-color: #4dbbff;
  border-color: #37b3ff;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(28, 145, 217, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #000000;
  background-color: #21aaff;
  border-color: #21aaff;
}

.btn-warning {
  color: #000000;
  background-color: #ff9922;
  border-color: #ff9922;
}
.btn-warning:hover {
  color: #000000;
  background-color: #ffa843;
  border-color: #ffa338;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000000;
  background-color: #ffa843;
  border-color: #ffa338;
  box-shadow: 0 0 0 0.25rem rgba(217, 130, 29, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #000000;
  background-color: #ffad4e;
  border-color: #ffa338;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 130, 29, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #000000;
  background-color: #ff9922;
  border-color: #ff9922;
}

.btn-danger {
  color: #ffffff;
  background-color: #b22830;
  border-color: #b22830;
}
.btn-danger:hover {
  color: #ffffff;
  background-color: #972229;
  border-color: #8e2026;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #ffffff;
  background-color: #972229;
  border-color: #8e2026;
  box-shadow: 0 0 0 0.25rem rgba(190, 72, 79, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #8e2026;
  border-color: #861e24;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(190, 72, 79, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #ffffff;
  background-color: #b22830;
  border-color: #b22830;
}

.btn-dark {
  color: #ffffff;
  background-color: #6d35d9;
  border-color: #6d35d9;
}
.btn-dark:hover {
  color: #ffffff;
  background-color: #5d2db8;
  border-color: #572aae;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #ffffff;
  background-color: #5d2db8;
  border-color: #572aae;
  box-shadow: 0 0 0 0.25rem rgba(131, 83, 223, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #572aae;
  border-color: #5228a3;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(131, 83, 223, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #ffffff;
  background-color: #6d35d9;
  border-color: #6d35d9;
}

.btn-black {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-black:hover {
  color: #ffffff;
  background-color: black;
  border-color: black;
}
.btn-check:focus + .btn-black, .btn-black:focus {
  color: #ffffff;
  background-color: black;
  border-color: black;
  box-shadow: 0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}
.btn-check:checked + .btn-black, .btn-check:active + .btn-black, .btn-black:active, .btn-black.active, .show > .btn-black.dropdown-toggle {
  color: #ffffff;
  background-color: black;
  border-color: black;
}
.btn-check:checked + .btn-black:focus, .btn-check:active + .btn-black:focus, .btn-black:active:focus, .btn-black.active:focus, .show > .btn-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}
.btn-black:disabled, .btn-black.disabled {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}

.btn-red {
  color: #ffffff;
  background-color: #b22830;
  border-color: #b22830;
}
.btn-red:hover {
  color: #ffffff;
  background-color: #972229;
  border-color: #8e2026;
}
.btn-check:focus + .btn-red, .btn-red:focus {
  color: #ffffff;
  background-color: #972229;
  border-color: #8e2026;
  box-shadow: 0 0 0 0.25rem rgba(190, 72, 79, 0.5);
}
.btn-check:checked + .btn-red, .btn-check:active + .btn-red, .btn-red:active, .btn-red.active, .show > .btn-red.dropdown-toggle {
  color: #ffffff;
  background-color: #8e2026;
  border-color: #861e24;
}
.btn-check:checked + .btn-red:focus, .btn-check:active + .btn-red:focus, .btn-red:active:focus, .btn-red.active:focus, .show > .btn-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(190, 72, 79, 0.5);
}
.btn-red:disabled, .btn-red.disabled {
  color: #ffffff;
  background-color: #b22830;
  border-color: #b22830;
}

.btn-orange {
  color: #000000;
  background-color: #ea630f;
  border-color: #ea630f;
}
.btn-orange:hover {
  color: #000000;
  background-color: #ed7a33;
  border-color: #ec7327;
}
.btn-check:focus + .btn-orange, .btn-orange:focus {
  color: #000000;
  background-color: #ed7a33;
  border-color: #ec7327;
  box-shadow: 0 0 0 0.25rem rgba(199, 84, 13, 0.5);
}
.btn-check:checked + .btn-orange, .btn-check:active + .btn-orange, .btn-orange:active, .btn-orange.active, .show > .btn-orange.dropdown-toggle {
  color: #000000;
  background-color: #ee823f;
  border-color: #ec7327;
}
.btn-check:checked + .btn-orange:focus, .btn-check:active + .btn-orange:focus, .btn-orange:active:focus, .btn-orange.active:focus, .show > .btn-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(199, 84, 13, 0.5);
}
.btn-orange:disabled, .btn-orange.disabled {
  color: #000000;
  background-color: #ea630f;
  border-color: #ea630f;
}

.btn-white {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-white:hover {
  color: #000000;
  background-color: white;
  border-color: white;
}
.btn-check:focus + .btn-white, .btn-white:focus {
  color: #000000;
  background-color: white;
  border-color: white;
  box-shadow: 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
}
.btn-check:checked + .btn-white, .btn-check:active + .btn-white, .btn-white:active, .btn-white.active, .show > .btn-white.dropdown-toggle {
  color: #000000;
  background-color: white;
  border-color: white;
}
.btn-check:checked + .btn-white:focus, .btn-check:active + .btn-white:focus, .btn-white:active:focus, .btn-white.active:focus, .show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
}
.btn-white:disabled, .btn-white.disabled {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-outline-primary {
  color: #006368;
  border-color: #006368;
}
.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #006368;
  border-color: #006368;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 99, 104, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #ffffff;
  background-color: #006368;
  border-color: #006368;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 99, 104, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #006368;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #6d35d9;
  border-color: #6d35d9;
}
.btn-outline-secondary:hover {
  color: #ffffff;
  background-color: #6d35d9;
  border-color: #6d35d9;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(109, 53, 217, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #ffffff;
  background-color: #6d35d9;
  border-color: #6d35d9;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(109, 53, 217, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #6d35d9;
  background-color: transparent;
}

.btn-outline-light {
  color: #f5eae1;
  border-color: #f5eae1;
}
.btn-outline-light:hover {
  color: #000000;
  background-color: #f5eae1;
  border-color: #f5eae1;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(245, 234, 225, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000000;
  background-color: #f5eae1;
  border-color: #f5eae1;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(245, 234, 225, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f5eae1;
  background-color: transparent;
}

.btn-outline-success {
  color: #3ea06b;
  border-color: #3ea06b;
}
.btn-outline-success:hover {
  color: #000000;
  background-color: #3ea06b;
  border-color: #3ea06b;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(62, 160, 107, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #000000;
  background-color: #3ea06b;
  border-color: #3ea06b;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(62, 160, 107, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #3ea06b;
  background-color: transparent;
}

.btn-outline-gray-100 {
  color: #f3f3f3;
  border-color: #f3f3f3;
}
.btn-outline-gray-100:hover {
  color: #000000;
  background-color: #f3f3f3;
  border-color: #f3f3f3;
}
.btn-check:focus + .btn-outline-gray-100, .btn-outline-gray-100:focus {
  box-shadow: 0 0 0 0.25rem rgba(243, 243, 243, 0.5);
}
.btn-check:checked + .btn-outline-gray-100, .btn-check:active + .btn-outline-gray-100, .btn-outline-gray-100:active, .btn-outline-gray-100.active, .btn-outline-gray-100.dropdown-toggle.show {
  color: #000000;
  background-color: #f3f3f3;
  border-color: #f3f3f3;
}
.btn-check:checked + .btn-outline-gray-100:focus, .btn-check:active + .btn-outline-gray-100:focus, .btn-outline-gray-100:active:focus, .btn-outline-gray-100.active:focus, .btn-outline-gray-100.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(243, 243, 243, 0.5);
}
.btn-outline-gray-100:disabled, .btn-outline-gray-100.disabled {
  color: #f3f3f3;
  background-color: transparent;
}

.btn-outline-gray-200 {
  color: #d0d0cf;
  border-color: #d0d0cf;
}
.btn-outline-gray-200:hover {
  color: #000000;
  background-color: #d0d0cf;
  border-color: #d0d0cf;
}
.btn-check:focus + .btn-outline-gray-200, .btn-outline-gray-200:focus {
  box-shadow: 0 0 0 0.25rem rgba(208, 208, 207, 0.5);
}
.btn-check:checked + .btn-outline-gray-200, .btn-check:active + .btn-outline-gray-200, .btn-outline-gray-200:active, .btn-outline-gray-200.active, .btn-outline-gray-200.dropdown-toggle.show {
  color: #000000;
  background-color: #d0d0cf;
  border-color: #d0d0cf;
}
.btn-check:checked + .btn-outline-gray-200:focus, .btn-check:active + .btn-outline-gray-200:focus, .btn-outline-gray-200:active:focus, .btn-outline-gray-200.active:focus, .btn-outline-gray-200.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(208, 208, 207, 0.5);
}
.btn-outline-gray-200:disabled, .btn-outline-gray-200.disabled {
  color: #d0d0cf;
  background-color: transparent;
}

.btn-outline-gray-300 {
  color: rgba(0, 99, 104, 0.2);
  border-color: rgba(0, 99, 104, 0.2);
}
.btn-outline-gray-300:hover {
  color: #ffffff;
  background-color: rgba(0, 99, 104, 0.2);
  border-color: rgba(0, 99, 104, 0.2);
}
.btn-check:focus + .btn-outline-gray-300, .btn-outline-gray-300:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 99, 104, 0.5);
}
.btn-check:checked + .btn-outline-gray-300, .btn-check:active + .btn-outline-gray-300, .btn-outline-gray-300:active, .btn-outline-gray-300.active, .btn-outline-gray-300.dropdown-toggle.show {
  color: #ffffff;
  background-color: rgba(0, 99, 104, 0.2);
  border-color: rgba(0, 99, 104, 0.2);
}
.btn-check:checked + .btn-outline-gray-300:focus, .btn-check:active + .btn-outline-gray-300:focus, .btn-outline-gray-300:active:focus, .btn-outline-gray-300.active:focus, .btn-outline-gray-300.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 99, 104, 0.5);
}
.btn-outline-gray-300:disabled, .btn-outline-gray-300.disabled {
  color: rgba(0, 99, 104, 0.2);
  background-color: transparent;
}

.btn-outline-gray-400 {
  color: #e2e2e2;
  border-color: #e2e2e2;
}
.btn-outline-gray-400:hover {
  color: #000000;
  background-color: #e2e2e2;
  border-color: #e2e2e2;
}
.btn-check:focus + .btn-outline-gray-400, .btn-outline-gray-400:focus {
  box-shadow: 0 0 0 0.25rem rgba(226, 226, 226, 0.5);
}
.btn-check:checked + .btn-outline-gray-400, .btn-check:active + .btn-outline-gray-400, .btn-outline-gray-400:active, .btn-outline-gray-400.active, .btn-outline-gray-400.dropdown-toggle.show {
  color: #000000;
  background-color: #e2e2e2;
  border-color: #e2e2e2;
}
.btn-check:checked + .btn-outline-gray-400:focus, .btn-check:active + .btn-outline-gray-400:focus, .btn-outline-gray-400:active:focus, .btn-outline-gray-400.active:focus, .btn-outline-gray-400.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(226, 226, 226, 0.5);
}
.btn-outline-gray-400:disabled, .btn-outline-gray-400.disabled {
  color: #e2e2e2;
  background-color: transparent;
}

.btn-outline-gray-500 {
  color: #808080;
  border-color: #808080;
}
.btn-outline-gray-500:hover {
  color: #000000;
  background-color: #808080;
  border-color: #808080;
}
.btn-check:focus + .btn-outline-gray-500, .btn-outline-gray-500:focus {
  box-shadow: 0 0 0 0.25rem rgba(128, 128, 128, 0.5);
}
.btn-check:checked + .btn-outline-gray-500, .btn-check:active + .btn-outline-gray-500, .btn-outline-gray-500:active, .btn-outline-gray-500.active, .btn-outline-gray-500.dropdown-toggle.show {
  color: #000000;
  background-color: #808080;
  border-color: #808080;
}
.btn-check:checked + .btn-outline-gray-500:focus, .btn-check:active + .btn-outline-gray-500:focus, .btn-outline-gray-500:active:focus, .btn-outline-gray-500.active:focus, .btn-outline-gray-500.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(128, 128, 128, 0.5);
}
.btn-outline-gray-500:disabled, .btn-outline-gray-500.disabled {
  color: #808080;
  background-color: transparent;
}

.btn-outline-gray-600 {
  color: #777777;
  border-color: #777777;
}
.btn-outline-gray-600:hover {
  color: #000000;
  background-color: #777777;
  border-color: #777777;
}
.btn-check:focus + .btn-outline-gray-600, .btn-outline-gray-600:focus {
  box-shadow: 0 0 0 0.25rem rgba(119, 119, 119, 0.5);
}
.btn-check:checked + .btn-outline-gray-600, .btn-check:active + .btn-outline-gray-600, .btn-outline-gray-600:active, .btn-outline-gray-600.active, .btn-outline-gray-600.dropdown-toggle.show {
  color: #000000;
  background-color: #777777;
  border-color: #777777;
}
.btn-check:checked + .btn-outline-gray-600:focus, .btn-check:active + .btn-outline-gray-600:focus, .btn-outline-gray-600:active:focus, .btn-outline-gray-600.active:focus, .btn-outline-gray-600.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(119, 119, 119, 0.5);
}
.btn-outline-gray-600:disabled, .btn-outline-gray-600.disabled {
  color: #777777;
  background-color: transparent;
}

.btn-outline-gray-800 {
  color: #929292;
  border-color: #929292;
}
.btn-outline-gray-800:hover {
  color: #000000;
  background-color: #929292;
  border-color: #929292;
}
.btn-check:focus + .btn-outline-gray-800, .btn-outline-gray-800:focus {
  box-shadow: 0 0 0 0.25rem rgba(146, 146, 146, 0.5);
}
.btn-check:checked + .btn-outline-gray-800, .btn-check:active + .btn-outline-gray-800, .btn-outline-gray-800:active, .btn-outline-gray-800.active, .btn-outline-gray-800.dropdown-toggle.show {
  color: #000000;
  background-color: #929292;
  border-color: #929292;
}
.btn-check:checked + .btn-outline-gray-800:focus, .btn-check:active + .btn-outline-gray-800:focus, .btn-outline-gray-800:active:focus, .btn-outline-gray-800.active:focus, .btn-outline-gray-800.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(146, 146, 146, 0.5);
}
.btn-outline-gray-800:disabled, .btn-outline-gray-800.disabled {
  color: #929292;
  background-color: transparent;
}

.btn-outline-gray-900 {
  color: #666666;
  border-color: #666666;
}
.btn-outline-gray-900:hover {
  color: #ffffff;
  background-color: #666666;
  border-color: #666666;
}
.btn-check:focus + .btn-outline-gray-900, .btn-outline-gray-900:focus {
  box-shadow: 0 0 0 0.25rem rgba(102, 102, 102, 0.5);
}
.btn-check:checked + .btn-outline-gray-900, .btn-check:active + .btn-outline-gray-900, .btn-outline-gray-900:active, .btn-outline-gray-900.active, .btn-outline-gray-900.dropdown-toggle.show {
  color: #ffffff;
  background-color: #666666;
  border-color: #666666;
}
.btn-check:checked + .btn-outline-gray-900:focus, .btn-check:active + .btn-outline-gray-900:focus, .btn-outline-gray-900:active:focus, .btn-outline-gray-900.active:focus, .btn-outline-gray-900.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(102, 102, 102, 0.5);
}
.btn-outline-gray-900:disabled, .btn-outline-gray-900.disabled {
  color: #666666;
  background-color: transparent;
}

.btn-outline-body {
  color: #1a1a1a;
  border-color: #1a1a1a;
}
.btn-outline-body:hover {
  color: #ffffff;
  background-color: #1a1a1a;
  border-color: #1a1a1a;
}
.btn-check:focus + .btn-outline-body, .btn-outline-body:focus {
  box-shadow: 0 0 0 0.25rem rgba(26, 26, 26, 0.5);
}
.btn-check:checked + .btn-outline-body, .btn-check:active + .btn-outline-body, .btn-outline-body:active, .btn-outline-body.active, .btn-outline-body.dropdown-toggle.show {
  color: #ffffff;
  background-color: #1a1a1a;
  border-color: #1a1a1a;
}
.btn-check:checked + .btn-outline-body:focus, .btn-check:active + .btn-outline-body:focus, .btn-outline-body:active:focus, .btn-outline-body.active:focus, .btn-outline-body.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(26, 26, 26, 0.5);
}
.btn-outline-body:disabled, .btn-outline-body.disabled {
  color: #1a1a1a;
  background-color: transparent;
}

.btn-outline-info {
  color: #21aaff;
  border-color: #21aaff;
}
.btn-outline-info:hover {
  color: #000000;
  background-color: #21aaff;
  border-color: #21aaff;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 170, 255, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000000;
  background-color: #21aaff;
  border-color: #21aaff;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 170, 255, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #21aaff;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ff9922;
  border-color: #ff9922;
}
.btn-outline-warning:hover {
  color: #000000;
  background-color: #ff9922;
  border-color: #ff9922;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 153, 34, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000000;
  background-color: #ff9922;
  border-color: #ff9922;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 153, 34, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ff9922;
  background-color: transparent;
}

.btn-outline-danger {
  color: #b22830;
  border-color: #b22830;
}
.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #b22830;
  border-color: #b22830;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(178, 40, 48, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #ffffff;
  background-color: #b22830;
  border-color: #b22830;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(178, 40, 48, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #b22830;
  background-color: transparent;
}

.btn-outline-dark {
  color: #6d35d9;
  border-color: #6d35d9;
}
.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #6d35d9;
  border-color: #6d35d9;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(109, 53, 217, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #ffffff;
  background-color: #6d35d9;
  border-color: #6d35d9;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(109, 53, 217, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #6d35d9;
  background-color: transparent;
}

.btn-outline-black {
  color: #000000;
  border-color: #000000;
}
.btn-outline-black:hover {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-check:focus + .btn-outline-black, .btn-outline-black:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.5);
}
.btn-check:checked + .btn-outline-black, .btn-check:active + .btn-outline-black, .btn-outline-black:active, .btn-outline-black.active, .btn-outline-black.dropdown-toggle.show {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-check:checked + .btn-outline-black:focus, .btn-check:active + .btn-outline-black:focus, .btn-outline-black:active:focus, .btn-outline-black.active:focus, .btn-outline-black.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.5);
}
.btn-outline-black:disabled, .btn-outline-black.disabled {
  color: #000000;
  background-color: transparent;
}

.btn-outline-red {
  color: #b22830;
  border-color: #b22830;
}
.btn-outline-red:hover {
  color: #ffffff;
  background-color: #b22830;
  border-color: #b22830;
}
.btn-check:focus + .btn-outline-red, .btn-outline-red:focus {
  box-shadow: 0 0 0 0.25rem rgba(178, 40, 48, 0.5);
}
.btn-check:checked + .btn-outline-red, .btn-check:active + .btn-outline-red, .btn-outline-red:active, .btn-outline-red.active, .btn-outline-red.dropdown-toggle.show {
  color: #ffffff;
  background-color: #b22830;
  border-color: #b22830;
}
.btn-check:checked + .btn-outline-red:focus, .btn-check:active + .btn-outline-red:focus, .btn-outline-red:active:focus, .btn-outline-red.active:focus, .btn-outline-red.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(178, 40, 48, 0.5);
}
.btn-outline-red:disabled, .btn-outline-red.disabled {
  color: #b22830;
  background-color: transparent;
}

.btn-outline-orange {
  color: #ea630f;
  border-color: #ea630f;
}
.btn-outline-orange:hover {
  color: #000000;
  background-color: #ea630f;
  border-color: #ea630f;
}
.btn-check:focus + .btn-outline-orange, .btn-outline-orange:focus {
  box-shadow: 0 0 0 0.25rem rgba(234, 99, 15, 0.5);
}
.btn-check:checked + .btn-outline-orange, .btn-check:active + .btn-outline-orange, .btn-outline-orange:active, .btn-outline-orange.active, .btn-outline-orange.dropdown-toggle.show {
  color: #000000;
  background-color: #ea630f;
  border-color: #ea630f;
}
.btn-check:checked + .btn-outline-orange:focus, .btn-check:active + .btn-outline-orange:focus, .btn-outline-orange:active:focus, .btn-outline-orange.active:focus, .btn-outline-orange.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(234, 99, 15, 0.5);
}
.btn-outline-orange:disabled, .btn-outline-orange.disabled {
  color: #ea630f;
  background-color: transparent;
}

.btn-outline-white {
  color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-white:hover {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-check:focus + .btn-outline-white, .btn-outline-white:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-outline-white, .btn-check:active + .btn-outline-white, .btn-outline-white:active, .btn-outline-white.active, .btn-outline-white.dropdown-toggle.show {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-check:checked + .btn-outline-white:focus, .btn-check:active + .btn-outline-white:focus, .btn-outline-white:active:focus, .btn-outline-white.active:focus, .btn-outline-white.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-outline-white:disabled, .btn-outline-white.disabled {
  color: #ffffff;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #006368;
  text-decoration: underline;
}
.btn-link:hover {
  color: #004f53;
}
.btn-link:disabled, .btn-link.disabled {
  color: #777777;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: calc(1.3125rem + 0.75vw);
  border-radius: 0.3rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0.6667rem 0.6667rem;
  border-bottom: 1px solid #d6d6d6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.33335rem 0.33335rem;
  margin: -0.33335rem -0.33335rem -0.33335rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0.6667rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.4167rem;
  border-top: 1px solid #d6d6d6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 99, 104, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: calc(1.3125rem + 0.75vw);
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #777777;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1a1a1a;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e2e2e2;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #1a1a1a;
  background-color: #ffffff;
  border-color: #80b1b4;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 99, 104, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::-moz-placeholder {
  color: #777777;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #777777;
  opacity: 1;
}
.form-control::placeholder {
  color: #777777;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #d0d0cf;
  opacity: 1;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
          margin-inline-end: 0.75rem;
  color: #1a1a1a;
  background-color: #d0d0cf;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
          margin-inline-end: 0.75rem;
  color: #1a1a1a;
  background-color: #d0d0cf;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #c6c6c5;
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #c6c6c5;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
          margin-inline-end: 0.75rem;
  color: #1a1a1a;
  background-color: #d0d0cf;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #c6c6c5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #1a1a1a;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
          margin-inline-end: 0.5rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
          margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
          margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: calc(1.3125rem + 0.75vw);
  border-radius: 0.3rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
          margin-inline-end: 1rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
          margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
          margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1a1a1a;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23929292' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #e2e2e2;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #80b1b4;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 99, 104, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #d0d0cf;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #1a1a1a;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: calc(1.3125rem + 0.75vw);
  border-radius: 0.3rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #80b1b4;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 99, 104, 0.25);
}
.form-check-input:checked {
  background-color: #21aaff;
  border-color: #21aaff;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #006368;
  border-color: #006368;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2380b1b4'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(0, 99, 104, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(0, 99, 104, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #006368;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
          appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b3d0d2;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: rgba(0, 99, 104, 0.2);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #006368;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
       appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b3d0d2;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: rgba(0, 99, 104, 0.2);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #808080;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #808080;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control:-ms-input-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-ms-input-placeholder) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1a1a1a;
  text-align: center;
  white-space: nowrap;
  background-color: #d0d0cf;
  border: 1px solid #e2e2e2;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(1.3125rem + 0.75vw);
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #3ea06b;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.166675rem 0.33335rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #000000;
  background-color: rgba(62, 160, 107, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #3ea06b;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233ea06b' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #3ea06b;
  box-shadow: 0 0 0 0.25rem rgba(62, 160, 107, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #3ea06b;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23929292' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233ea06b' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #3ea06b;
  box-shadow: 0 0 0 0.25rem rgba(62, 160, 107, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #3ea06b;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #3ea06b;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(62, 160, 107, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #3ea06b;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #b22830;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.166675rem 0.33335rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #ffffff;
  background-color: rgba(178, 40, 48, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #b22830;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23b22830'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23b22830' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #b22830;
  box-shadow: 0 0 0 0.25rem rgba(178, 40, 48, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #b22830;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23929292' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23b22830'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23b22830' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #b22830;
  box-shadow: 0 0 0 0.25rem rgba(178, 40, 48, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #b22830;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #b22830;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(178, 40, 48, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #b22830;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.bar-wrapper {
  display: block;
  position: relative;
  background: transparent;
  z-index: 9999;
  width: 30px;
  background-image: none;
  padding: 11px 10px;
  border: 1px solid transparent;
}
.bar-wrapper span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #006368;
  opacity: 1;
  left: 0;
  transition: transform 0.2s linear, top 0.3s linear, width 0.3s ease-in-out, opacity 0.5s ease-in-out;
}
.bar-wrapper span:first-child {
  top: 0;
}
.bar-wrapper span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}
.bar-wrapper span:nth-child(3) {
  bottom: 0;
}

body.menu-open header .bar-wrapper span:first-child {
  transform: rotate(45deg);
  top: 50%;
  width: 100%;
}
body.menu-open header .bar-wrapper span:nth-child(2) {
  width: 0;
  opacity: 0;
}
body.menu-open header .bar-wrapper span:last-child {
  transform: rotate(-45deg);
  bottom: unset;
  top: 50%;
}
body.menu-open .main_menu {
  left: 0;
}

.loading-first {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: 9999;
  display: flex;
}
.loading-first .loader {
  position: absolute;
  top: 50%;
  left: calc(50% - 15px);
  transform: translate(-50%, -50%);
  -webkit-animation: loader4Animation 1s linear infinite;
          animation: loader4Animation 1s linear infinite;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000000;
}
.loading-first .loader:before, .loading-first .loader:after {
  display: none !important;
}
.loading-first.hided {
  display: none;
}

@-webkit-keyframes loader4Animation {
  0% {
    background-color: #6d35ff;
    box-shadow: 15px 0px 0px 0px rgba(109, 53, 255, 0.67), 30px 0px 0px 0px rgba(109, 53, 255, 0.33);
  }
  17% {
    background-color: rgba(109, 53, 255, 0.67);
    box-shadow: 15px 0px 0px 0px #6d35ff, 30px 0px 0px 0px rgba(109, 53, 255, 0.67);
  }
  33% {
    background-color: rgba(109, 53, 255, 0.33);
    box-shadow: 15px 0px 0px 0px rgba(109, 53, 255, 0.67), 30px 0px 0px 0px #6d35ff;
  }
  50% {
    background-color: rgba(109, 53, 255, 0);
    box-shadow: 15px 0px 0px 0px rgba(109, 53, 255, 0.33), 30px 0px 0px 0px rgba(24, 28, 34, 0.67);
  }
  67% {
    background-color: rgba(109, 53, 255, 0.33);
    box-shadow: 15px 0px 0px 0px rgba(109, 53, 255, 0), 30px 0px 0px 0px rgba(109, 53, 255, 0.33);
  }
  83% {
    background-color: rgba(109, 53, 255, 0.67);
    box-shadow: 15px 0px 0px 0px rgba(109, 53, 255, 0.33), 30px 0px 0px 0px rgba(109, 53, 255, 0);
  }
  100% {
    background-color: #6d35ff;
    box-shadow: 15px 0px 0px 0px rgba(109, 53, 255, 0.67), 30px 0px 0px 0px rgba(109, 53, 255, 0.33);
  }
}

@keyframes loader4Animation {
  0% {
    background-color: #6d35ff;
    box-shadow: 15px 0px 0px 0px rgba(109, 53, 255, 0.67), 30px 0px 0px 0px rgba(109, 53, 255, 0.33);
  }
  17% {
    background-color: rgba(109, 53, 255, 0.67);
    box-shadow: 15px 0px 0px 0px #6d35ff, 30px 0px 0px 0px rgba(109, 53, 255, 0.67);
  }
  33% {
    background-color: rgba(109, 53, 255, 0.33);
    box-shadow: 15px 0px 0px 0px rgba(109, 53, 255, 0.67), 30px 0px 0px 0px #6d35ff;
  }
  50% {
    background-color: rgba(109, 53, 255, 0);
    box-shadow: 15px 0px 0px 0px rgba(109, 53, 255, 0.33), 30px 0px 0px 0px rgba(24, 28, 34, 0.67);
  }
  67% {
    background-color: rgba(109, 53, 255, 0.33);
    box-shadow: 15px 0px 0px 0px rgba(109, 53, 255, 0), 30px 0px 0px 0px rgba(109, 53, 255, 0.33);
  }
  83% {
    background-color: rgba(109, 53, 255, 0.67);
    box-shadow: 15px 0px 0px 0px rgba(109, 53, 255, 0.33), 30px 0px 0px 0px rgba(109, 53, 255, 0);
  }
  100% {
    background-color: #6d35ff;
    box-shadow: 15px 0px 0px 0px rgba(109, 53, 255, 0.67), 30px 0px 0px 0px rgba(109, 53, 255, 0.33);
  }
}
.header_logo img {
  width: 70px;
  border-radius: 15px;
}
.header.affix .header_logo img {
  width: 55px;
  border-radius: 10px;
}
.header_search img {
  width: 27px;
}
.header_search .search-submit {
  width: 38px;
  height: 38px;
  background: url("root/images/search.png") center center no-repeat transparent;
  background-size: 18px;
  border: none;
  font-size: 0;
}

#closeIcon img {
  width: 20px !important;
}

.main_form.show {
  display: flex !important;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  left: 0;
  top: 0;
  z-index: 9999;
  padding-top: 20vh;
  padding-right: 10px;
}
.main_form.show form {
  width: 100%;
}

.main_menu {
  transition: 0.3s;
  left: -100vw;
  width: 100%;
}
.main_menu ul li a {
  display: block;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #006368;
  color: #000000;
  transition: inherit;
}
.main_menu ul li.current_page_item > a, .main_menu ul li.current-menu-parent > a {
  color: #6d35d9 !important;
}
.main_menu ul li.menu-item-has-children .sub-menu {
  width: 100%;
  padding: 0 25px;
}
.main_menu ul li.menu-item-has-children .sub-menu li {
  margin-bottom: 12px;
}
.main_menu ul li.menu-item-has-children .sub-menu li a {
  font-size: 20px;
  border-bottom: 1px solid #006368;
  line-height: 1.3;
  display: block;
}
@media only screen and (max-width: 991px) {
  .menu-main-menu-container {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.footer {
  color: #ffffff;
}
.footer a {
  color: #ffffff;
}
.footer a:hover {
  text-decoration: underline;
  color: #ffffff;
}
.footer ul {
  margin-bottom: 0;
}
.footer ul li {
  padding-bottom: 10px;
}
.footer_address .sub-menu {
  display: none !important;
}
.footer_contact ul li {
  margin-bottom: 5px;
}
.footer_contact ul li a {
  display: flex;
  word-break: break-all;
}
.footer_contact ul li a i {
  margin-right: 10px;
  background-color: #ffffff;
  color: #006368;
  width: 30px;
  text-align: center;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer_icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding-top: 10px;
  z-index: 2;
}
.footer_icon ul {
  justify-content: center;
}
.footer_icon ul li img {
  width: 45px;
}
.footer_icon ul li.vps img {
  border-radius: 50%;
  box-shadow: 0 0 5px #000000;
}
#total-items-count,
#total-items {
  position: absolute;
  top: -20px;
  right: -8px;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 12px;
  background: #000000;
  border-radius: 50%;
  color: #ffffff;
  padding-top: 1px;
}

.mini-quote-dropdown {
  position: fixed;
  height: 100vh;
  width: 560px;
  max-width: 100%;
  right: -190vw;
  top: 0;
  z-index: 99999;
  box-shadow: 0 0 4px 5px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  transition: 0.3s;
}
.mini-quote-dropdown:before {
  position: fixed;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99;
  transition: 0.3s;
  display: none;
}
.mini-quote-dropdown.show {
  right: 0;
}
.mini-quote-dropdown.show:before {
  display: block;
}
.mini-quote-dropdown.show .cart_module {
  position: relative;
  z-index: 999;
  height: 100%;
  background: #ffffff;
}
.mini-quote-dropdown ul li .img {
  width: 80px;
}
.mini-quote-dropdown ul li .img img {
  margin: 0 !important;
  width: 100%;
  height: inherit;
  float: none;
}
.mini-quote-dropdown ul li .text_ct {
  width: calc(100% - 80px);
  padding-left: 15px;
}
.mini-quote-dropdown ul li .tit {
  width: calc(100% - 145px);
}
.mini-quote-dropdown ul li .price {
  width: 145px;
}
.mini-quote-dropdown ul li .price img {
  width: 20px;
}

/**
 * @package     Wordpress.Site
 * @subpackage  Templates.thanh2t
 *
 * @copyright   Copyright (C) 2020 thanh2t. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */
/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * all-round-gothic:
 *   - http://typekit.com/eulas/00000000000000003b9b3766
 *   - http://typekit.com/eulas/00000000000000003b9b3767
 *   - http://typekit.com/eulas/00000000000000003b9b376c
 *   - http://typekit.com/eulas/00000000000000003b9b376d
 * raleway:
 *   - http://typekit.com/eulas/00000000000000003b9b13b9
 *   - http://typekit.com/eulas/00000000000000003b9b13b3
 *   - http://typekit.com/eulas/00000000000000003b9b13b2
 *   - http://typekit.com/eulas/00000000000000003b9b13b8
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2020-10-23 05:50:06 UTC"}*/
@font-face {
  font-family: "OpenSans";
  src: url("root/fonts/OpenSans.woff2") format("woff2"), url("root/fonts/OpenSans.woff") format("woff"), url("root/fonts/OpenSans.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Oswald";
  src: url("root/fonts/Oswald.woff2") format("woff2"), url("root/fonts/Oswald.woff") format("woff"), url("root/fonts/Oswald.ttf") format("truetype");
  font-display: swap;
}
* {
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
  overflow-x: hidden;
}

body {
  min-height: 100%;
  position: relative;
}

img {
  max-width: 100%;
  height: auto;
}

.img-effect {
  overflow: hidden;
}
.img-effect img {
  transition: 0.3s;
}
.img-effect:hover img {
  transform: scale(1.08);
}

a {
  text-decoration: none;
  color: #000000;
}
a:hover, a:focus {
  color: #666666;
}

.ls-1 {
  letter-spacing: 1px;
}

figcaption {
  text-align: center;
}

ul {
  padding: 0;
  list-style: none;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  font-family: "Oswald", sans-serif;
  color: #1a1a1a;
}
h6 a,
.h6 a,
h5 a,
.h5 a,
h4 a,
.h4 a,
h3 a,
.h3 a,
h2 a,
.h2 a,
h1 a,
.h1 a {
  color: #1a1a1a;
  transition: 0.3s;
}
h6 a:hover,
.h6 a:hover,
h5 a:hover,
.h5 a:hover,
h4 a:hover,
.h4 a:hover,
h3 a:hover,
.h3 a:hover,
h2 a:hover,
.h2 a:hover,
h1 a:hover,
.h1 a:hover {
  color: #1a1a1a;
}

video:focus {
  outline: none;
}

.pager-list .st-pagelink {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.pager-list .st-pagelink span,
.pager-list .st-pagelink a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #006368;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Oswald", sans-serif;
}
.pager-list .st-pagelink span {
  background: #006368;
  color: #ffffff;
}
.pager-list .st-pagelink a {
  color: #006368;
}
.pager-list .st-pagelink img {
  width: 15px;
}

.btn-primary {
  color: #ffffff;
  border: 1px solid #006368;
  display: inline-block;
  text-align: center;
  padding: 8px 10px 9px;
  border-radius: 0;
  transition: 0.3s;
  min-width: 170px;
  background-color: #006368;
  font-size: 18px;
}
.btn-primary:hover, .btn-primary:active {
  background-color: #ffffff;
  color: #006368;
  text-decoration: none;
  border: 1px solid #006368;
  box-shadow: none;
}
.btn-primary a {
  color: #ffffff;
}
.btn-primary:hover a, .btn-primary:active a {
  color: #006368;
}

.btn-secondary {
  font-family: "Oswald", sans-serif;
  color: #6d35d9;
  background: transparent;
  font-size: 18px;
  position: relative;
  display: inline-block;
  padding-bottom: 5px;
}
.btn-secondary:before {
  position: absolute;
  content: "";
  width: 35px;
  height: 2px;
  background-color: #6d35d9;
  bottom: 0;
  left: 0;
}
.btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus {
  background-color: transparent;
  color: #6d35d9;
  box-shadow: unset;
}

input:focus {
  outline: none;
}

main ul {
  list-style: disc;
  padding-left: 17px;
}
main ol {
  list-style: decimal;
  padding-left: 17px;
}

.swiper-pagination {
  width: 100%;
  margin: 0;
  bottom: 20px !important;
}
.swiper-pagination .swiper-pagination-bullet {
  background-color: #6d35d9;
  opacity: 0.5;
  margin: 0 3px;
}
.swiper-pagination .swiper-pagination-bullet-active {
  opacity: 1;
}

#swiper-slider-blog {
  padding-left: 1px;
}

.swiper-button-prev,
.swiper-button-next {
  height: 80px !important;
  width: 20px !important;
  background: rgba(0, 0, 0, 0.2);
  transform: translateY(-50%);
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 16px !important;
  color: #ffffff;
}

.heading-style {
  position: relative;
  padding-bottom: 15px;
}
.heading-style:before {
  position: absolute;
  content: "";
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 50px;
  height: 5px;
  background-color: #6d35d9;
}

.swiper-button-prev {
  left: 0 !important;
}
.swiper-button-prev:focus {
  outline: none;
}

.swiper-button-next {
  right: 0 !important;
}
.swiper-button-next:focus {
  outline: none;
}

.overlay {
  position: relative;
}
.overlay:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

input[type=text],
input[type=email],
input[type=password],
input[type=tel] {
  width: 100%;
  font-size: 15px;
  padding: 10px 15px;
  color: #1a1a1a;
  border: 1px solid #6d35d9;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: none;
  box-shadow: none;
}
input[type=text]:focus,
input[type=email]:focus,
input[type=password]:focus,
input[type=tel]:focus {
  outline: none;
}

textarea {
  height: 80px;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #6d35d9;
  height: 108px;
  padding: 10px 15px;
  font-size: 15px;
  color: #1a1a1a;
  resize: none;
}
textarea:focus {
  outline: none;
}

input[type=radio] + span:before {
  border-radius: 50%;
}
input[type=radio]:checked + span:after {
  top: 10px;
}

select {
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--bs-primary);
  background: var(--bs-white);
  color: var(--bs-body-color);
  padding: 0 15px 0 10px;
  margin-right: 0 !important;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: none;
}
select:focus-visible {
  outline: none;
}

.woocommerce-error,
.woocommerce-info,
.woocommerce-message {
  margin: 0 !important;
}

.show-password-input {
  display: none;
}

.scroll_style {
  overflow-x: hidden;
  overflow-y: auto;
}
.scroll_style ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
  border-radius: 10px;
  background-color: #fff;
}
.scroll_style ::-webkit-scrollbar {
  width: 7px;
  background-color: #f3f3f3;
}
.scroll_style ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px transparent;
  background-color: #d0d0cf;
  box-shadow: inset 0 0 6px transparent;
}

.page404 {
  background: url("root/images/404.jpg") left top no-repeat;
  background-size: cover;
  background-position: center;
  padding: 230px 0 !important;
}
.page404 .container {
  max-width: 950px;
}

.news_detail {
  padding: 87px 0 0;
  position: relative;
}
.news_detail:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 560px;
  background-color: #f3f3f3;
  left: 0;
  top: 0;
  z-index: -1;
}
.news_detail_ct {
  text-align: justify;
  line-height: 1.7;
  position: relative;
}
.news_detail_ct ul {
  list-style: disc;
  padding-left: 17px;
}
.news_detail_ct ol {
  list-style: decimal;
  padding-left: 17px;
}
.news_detail_ct h2 {
  font-size: 24px;
  margin-bottom: 1rem;
}
.news_detail_share {
  position: absolute;
  left: -30px;
  top: 238px;
}
.news_detail_share .p01 {
  text-transform: uppercase;
  margin-bottom: 8px;
}
.news_detail_share .lst {
  padding-left: 0;
}
.news_detail_share .lst li {
  width: 48px;
  height: 48px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 20px;
  border-radius: 50%;
  margin-bottom: 16px;
  cursor: pointer;
}
.news_detail_share .lst li.facebook-share {
  background-color: #3b5998;
}
.news_detail_share .lst li.twitter-share {
  background-color: #1da1f2;
}
.news_detail_share .lst li.linkedIn-share {
  background-color: #2867b2;
}
.news_detail_block a {
  color: #d81f26 !important;
}
.news_detail_block a:hover {
  text-decoration: underline;
}
.news_detail_img {
  margin-bottom: 25px;
  text-align: center;
}
.news_detail_img img {
  border-radius: 20px;
}
.news_detail h1 {
  font-size: 40px;
  text-align: center;
  margin-bottom: 15px;
}
.news_detail .date {
  text-align: center;
  margin-bottom: 35px;
  color: #006368;
}
.news_detail_comment {
  background-color: #f3f3f3;
  border-top: 1px solid #d6d6d6;
  padding: 45px 0 55px;
}
.news_detail_comment #comments {
  display: none;
}
.news_detail_comment .container {
  max-width: 738px;
}
.news_detail_comment h3 {
  font-size: 32px;
}
.news_detail_comment .form-submit input {
  background-color: #006368;
  color: #ffffff;
  height: 50px;
  padding: 11px 0 8px;
  text-align: center;
  font-size: 20px;
  border-radius: 10px;
  width: 160px;
  display: inline-block;
  line-height: 1.5;
  border: none;
}
.news_detail_comment .form-submit input:focus {
  outline: none;
}
.news_detail_comment .form-submit input:hover {
  color: #ffffff;
}
.news_detail_comment .required {
  color: #b22830;
}
.news_detail_comment label {
  margin-bottom: 10px;
}
.news_detail_comment form {
  display: flex;
  flex-wrap: wrap;
}
.news_detail_comment .comment-notes {
  color: #006368;
  margin-bottom: 22px;
  width: 100%;
}
.news_detail_comment .comment-notes .required {
  color: #006368;
}
.news_detail_comment .comment-form-comment {
  width: 100%;
  margin-bottom: 9px;
}
.news_detail_comment .comment-form-author {
  margin-right: 30px;
}
.news_detail_comment .comment-form-author,
.news_detail_comment .comment-form-email {
  width: calc(50% - 15px);
}
.news_detail_comment footer {
  background-color: transparent;
  color: #006368;
}
.news_detail_comment footer .says,
.news_detail_comment footer .comment-metadata,
.news_detail_comment footer .comment-awaiting-moderation {
  display: none;
}
.news_detail .commentlist {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}
.news_detail .commentlist li {
  list-style: none;
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 12px;
  margin-bottom: 25px;
}
.news_detail .commentlist li .comment-author {
  margin-bottom: 18px;
}
.news_detail .commentlist li .comment-author img {
  width: 50px;
  border-radius: 50%;
  margin-right: 5px;
}
.news_detail .commentlist li .comment-author .fn {
  font-size: 18px;
}
.news_detail .commentlist li .comment-content {
  line-height: 1.7;
}
.news_detail .commentlist li .children {
  padding-left: 60px;
}
.news_detail .commentlist li .children li {
  border-bottom: none;
}
.news_detail .commentlist li .children li .comment-body {
  display: flex;
  font-size: 14px;
  flex-wrap: wrap;
}
.news_detail .commentlist li .children li .comment-body .comment-meta {
  width: 100%;
  text-transform: capitalize;
}
.news_detail .commentlist li .children li .comment-body .fn a {
  color: #b22830;
}
.news_detail .reply {
  display: none;
}
.news_detail_related {
  padding: 57px 0 35px 0;
}
.news_detail_related h3 {
  font-size: 40px;
  text-align: center;
  margin-bottom: 45px;
}
.news_detail_related ul li figure {
  margin-bottom: 20px;
}
.news_detail_related ul li h4 {
  font-size: 24px;
  line-height: 1.5;
}

.st-pagelink {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
  padding-top: 40px;
}
.st-pagelink span,
.st-pagelink a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1a1a1a;
  background-color: #ffffff;
  border: 1px solid #d6d6d6;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 8px 16px;
  text-decoration: none;
  cursor: pointer;
  transition: 0.4s;
}
.st-pagelink span img,
.st-pagelink a img {
  width: 15px;
}
.st-pagelink span.page-numbers,
.st-pagelink a.page-numbers {
  border: 1px solid #d6d6d6 !important;
}
.st-pagelink a:hover {
  color: #ffffff;
  background-color: #006368;
  border: 1px solid #006368;
}
.st-pagelink a:hover img {
  filter: invert(1);
}
.st-pagelink span {
  color: #ffffff;
  background-color: #006368;
  border: 1px solid #006368;
}

@media screen and (max-width: 600px) {
  .wp-block-gallery .wp-block-image {
    width: 100% !important;
    margin-right: 0 !important;
  }
}
@media screen and (max-width: 1370px) {
  body .news_detail_ct {
    padding-left: 15px !important;
  }
  body .news_detail_share {
    position: static;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  body .news_detail_share .p01 {
    margin-bottom: 0;
    margin-right: 15px;
  }
  body .news_detail_share .lst {
    display: flex;
    margin-bottom: 0;
  }
  body .news_detail_share .lst li {
    margin-right: 10px;
    margin-bottom: 0;
  }
  body .news_detail .dtl_right {
    margin-top: 78px;
  }
}
@media screen and (max-width: 991px) {
  .news_detail_comment .comment-form-author,
.news_detail_comment .comment-form-email {
    width: 100%;
    margin-right: 0;
  }
}